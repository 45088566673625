.portal-section .portal-section-body-position {
    max-width: 1200px;
    margin: 0 auto;
    padding: calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 13) calc(var(--gap) * 2);
    padding-top: 0 !important;
}

.portal-section .mandant-overview__project-long-block {
    margin-top: calc(var(--gap) * 18);
    hyphens: auto;
}

.portal-section .project-list-contianer {
    max-width: 2000px;
    margin: 0 auto;
}