.menu-editor-submenu.MuiList-root {
    padding-left: calc(  var(--gap) * 2 );
}

.menu-editor .MuiListItemIcon-root {
    min-width: 36px;
}

.menu-editor .MuiListItemIcon-root:hover {
    color: var(--primary-color);
}