.imagesContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
}
.map-card > h3 {
    font-size: 20px;
    line-height: 20px;
    color: rgb(119, 119, 119);
    margin-bottom: 16px;
}

.checkboxPaper {
    text-align: left;
}
.Headline {
    text-align: left;
    padding-bottom: 10px;
}
.imgPaper {
    padding: 10px;
}
.large {
    width: 100%;
    height: 100%;
    min-width: 220px;
    min-height: 110px;
}

.dates {
    display: flex;
    justify-content: start;
    gap: 10px;
}
