.form-container {
    box-shadow: 3px 5px 2px rgb(255, 105, 135);
}
.form-container * {
}
.form-container .MuiButton-textPrimary {
    margin-left: 5px;
}
.form-wrapper {
    border: 1px solid #cccc;
}
