.portal-section .mandant-overview__logo-container {
    padding: var(--gap);
    max-width: 100%;
    margin-bottom: calc(var(--gap) * 5);
}

@media screen and (max-width: 1200px) {
    .portal-section .project-long-block__image {
        width: 50%;
    }
}

.portal-section .project-log-block__second-title,
.portal-section .project-log-block__first-title {
    margin-bottom: calc(var(--gap) * 2);
    display: flex;
    align-items: center;
}
.project-log-block__first-title__icon{
  margin-right: 5px;
}
.portal-section .project-log-block__controls {
    margin-top: calc(var(--gap) * 2);
}

.portal-section .project-long-block__padding-left {
    padding-left: 15%;
    padding-right: calc(var(--gap) * 4);
}

@media screen and (max-width: 1200px) {
    .portal-section .project-long-block__padding-left {
        padding-right: calc(var(--gap) * 4);
    }
}

.portal-section .project-long-block__padding-right {
    padding-right: 15%;
    padding-left: calc(var(--gap) * 4);
}


.portal-section .project-long-block__margin-image-left {
    margin-left: calc(var(--gap) * 2);
}


.portal-section .project-long-block__margin-image-right {
    margin-right: calc(var(--gap) * 2);
}

.portal-section .project-log-block__card {
    display: flex;
    box-shadow: none;
    margin-bottom: calc(var(--gap) * 18);
}

.portal-section .mandant_mobile-image {
    margin-bottom: calc(var(--gap) * 3);
}

.portal-section .mandant_mobile-image>img {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 576px) {
    .portal-section .mandant_mobile-image {
        height: 190px;
    }
}

@media screen and (max-width: 970px) {
    .portal-section .mandant_mobile-image {
        height: calc(100% * 0.54);
        width: 100%;
    }
}

.portal-section .mandant_mobile-title {
    margin: calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 2);
    width: 80%;
}

.portal-section .mandant_mobile-description-container {
    padding: 0 calc(var(--gap) * 2) calc(var(--gap) * 4) calc(var(--gap) * 2);
}

.portal-section .mandant_mobile-description {
    padding: 0 0 calc(var(--gap) * 4) 0;
}

.portal-section .mandant_mobile-description>button {
    padding: var(--gap) 0 calc(var(--gap) * 4) 0;
}

.portal-section .mandant_mobile-container {
    padding: 0 0 calc(var(--gap) * 15) 0;
}

.portal-section .project-log-block__details {
    width: 35%;
}

.portal-section .project-log-block__image.with-shadow {
    width: 50%;
}

@media screen and (min-width: 1500px)and (max-width:2000px) {
    .portal-section .project-log-block__details {
        width: calc(100vw - 801px - var(--gap)*8 - 15%);
    }

    .portal-section .project-log-block__image.with-shadow {
        height: 421px;
        width: 800px;
    }
}

@media screen and (min-width: 2000px) {
    .portal-section .project-log-block__details {
        width: calc(2000px - 801px - var(--gap)*6 - 15%);
    }

    .portal-section .project-log-block__image.with-shadow {
        height: 421px;
        width: 800px;
    }
}

.portal-section .project-long-block__image {
    border-radius: var(--border-radius-paper);
}

.portal-section .project-log-block__image.with-shadow>img {
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius-paper);
}