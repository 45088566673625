
.portal-section .App-header .menu-with-no-buttons {
    margin: 0 calc(var(--gap) *4) !important; /* we use the same gap of the image on the left*/
    left:178px !important; /* 146 image with + 32px gap*/
}
.portal-section .App-header .menu-with-no-buttons-with-big-logo{
    margin: 0 calc(var(--gap) *4) !important; /* we use the same gap of the image on the left*/
    left:248px !important; /* 216 image width + 32px gap*/
}
.portal-section .App-header .centred-menu-with-no-buttons {
    max-width:unset; /* we need to unset the max-width because it's bigger than 1200 */
    width: calc(100% - 420px) !important; /* 178px * 2 + 32*2 make the width the rest after removing the image section from both sides nd considering tha gaps*/

}
.portal-section .App-header .centred-menu-with-no-buttons-with-big-image {
    max-width:unset; /* we need to unset the max-width because it's bigger than 1200 */
    width: calc(100% - 560px) !important; /* 248px * 2 + 32*2 make the width the rest after removing the image section from both sides and considering tha gaps*/

}
.portal-section .App-header .full-width-menu-with-no-buttons{
    width: calc(100% - 242px) !important; /* 178px image container width and gap +32*2 gap from both sides */
}

.portal-section .App-header .full-width-menu-with-no-buttons-with-big-image{
    width: calc(100% - 312px) !important; /* 248 image container width and gap +32*2 gap from both sides */
}

.portal-section .App-header .full-width-menu-with-no-buttons.full-width{
    width: calc(100% - 80px) !important; /* 32 px *2 gap + 8*2 padding of the container */
    margin: 0 40px 0 0 !important; /* 32 + 8 gap */
    left: 40px !important;  /* 32 + 8  gap */
}