.card-image-container{
    position: relative ;
}
.archived-icon-container{
    width: 100%;
    height: 160px;
    position: absolute;
    top:0;
    bottom: 0; 
    background-color :rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.archived-icon-container__archived-icon{
    width: 3rem !important;
    height: 3rem !important;
}