.portal-section .App-header .App-header-user-button.MuiButtonBase-root {
    background-color: var(--button-background-dark) !important;
    color: white !important;
}

.portal-section .App-header .MuiTypography-root.header-text {
    color: white;
}

.portal-section .App-header-primary-button,
.portal-section .MuiButton-textPrimary {
    background-color: var(--primary-color) !important;
    color: var(--button-text-color) !important;
}

