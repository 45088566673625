.sticky-menu {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 100px;
}
.faq-list__container__questions{
    width: 100%;
}
.faq-list__container__questions__buttons {
    margin-bottom: 8px !important;
}
.faq-list-answers__container__item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px !important;
    width:100%
}
.faq-list-answers__container__item .main-paper{
    width:85%
}
.faq-list-answers__container__item__button{
    width: 15%;
}

.faq-list__container .MuiListItem-button{
transition: none !important;
}

.aligned-header{
    display: flex;
    width: 100%;
}

.divider {
    height: 3px !important;
}
.sorting-item {
    border:solid 1px !important;
    border-color: var(--button-border) !important;
}