.preview-container{
    background-color: #e76200;
    opacity: 50%;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    height:20px;
    width: 100%;
    font-size: 15px;
    font-weight: bold;

}