.portal-section .App-footer .MuiOutlinedInput-notchedOutline,
.portal-section .App-footer .MuiOutlinedInput-notchedOutline:hover,
.portal-section .App-footer .MuiOutlinedInput-notchedOutline:focus,
.portal-section .App-footer .MuiSelect-select .MuiOutlinedInput-notchedOutline,
.portal-section
    .App-footer
    .MuiSelect-select:focus
    .MuiOutlinedInput-notchedOutline,
.portal-section
    .App-footer
    .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid #696969;
    /* should replace the variable by css color varibale */
}

.footer-email-name-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width:940px) {
    .footer-email-name-container {
        display: block;
    }
    div.footer-email-name-container > div {
        width: 100%;
    } 
}

.footer-email-name-container > div {
    width: 49.5%;
}

.App-footer-contact {
    width: 100%;
}

.portal-section .contact-form__inputs > div > input {
    height: 25px;
}
