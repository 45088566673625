.toLeft {
    position: relative;
    right: 30%;
}
.archived-project-checkbox-container {
    display: flex;
    justify-content: end;
}

.archived-project-checkbox-container__checkbox.Mui-checked{
    color:#00b5e2 !important;
}