.admin-container {
    color: #606060;
}

.admin-container main.content {
    padding-top: 20px;
    max-width: 1600px;
}

.admin-container .MuiOutlinedInput-multiline {
    padding: 16px;
}
.admin-container .MuiButton-textPrimary {
    color: white;
    border: 0;
    height: 35px;
    margin: 16px 16px 16px 0;
    padding: 20px 30px;
    background: linear-gradient(45deg, #00b5e2 30%, #00b5e2 90%);
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
    border-radius: 0;
}

.admin-container .cmsHoverContent:hover {
    background: #f0f0f0;
    cursor: pointer;
}

.admin-container .MuiPaper-rounded,
.admin-container .MuiOutlinedInput-root {
    border-radius: 0;
}

.admin-container .MuiButton-textSecondary {
    color: white;
    border: 0;
    height: 35px;
    margin: 16px 16px 16px 0;
    padding: 20px 30px;
    background: linear-gradient(
        45deg,
        rgb(119, 119, 119) 30%,
        rgb(119, 119, 119) 90%
    );
    background-color: rgb(119, 119, 119);
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
    border-radius: 0;
}

.admin-container .MuiTabs-indicator {
    height: 4px;
    background-color: rgb(119, 119, 119);
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover {
    background-color: #00b5e2 !important;
}

.admin-container a {
    color: rgb(119, 119, 119);
}
.admin-container a:hover {
    color: #00b5e2;
}

.admin-container .MuiCardActions-root .MuiButton-textPrimary,
.admin-container .MuiCardActions-root .MuiButton-textSecondary {
    margin: 8px 8px 8px 0;
}

.admin-container .MuiCardActions-root {
    padding: 8px 8px 8px 16px;
}

.admin-container .MuiTab-root.MuiTab-textColorInherit:hover {
    background: white;
    color: rgb(119, 119, 119);
    opacity: 1;
}

/*
.admin-container .MuiTouchRipple-root {
  color: rgb(119, 119, 119);
}
*/

.admin-container .MuiTableCell-head {
    color: white;
    background: #606060;
    font-weight: bold;
}

.admin-container .main-header {
    color: rgb(119, 119, 119);
    font-size: 24px;
    margin-bottom: 8px;
}

.admin-container strong {
    font-weight: bold;
}

.admin-container .gap-top-1 {
    margin-top: 8px;
}

.admin-container .gap-top {
    margin-top: 16px;
}

.admin-container .gap-bottom {
    margin-bottom: 16px;
}

.admin-container .gap-bottom_1_5 {
    margin-bottom: 24px;
}

.admin-container .gap-bottom_2 {
    margin-bottom: 32px;
}

.admin-container .gap-left {
    margin-left: 16px;
}

.admin-container h2,
.admin-container h2.main-header {
    font-size: 24px;
    line-height: 24px;
}

.admin-container h3,
.admin-container h3.main-header {
    font-size: 20px;
    line-height: 20px;
}

.admin-container .main-header-with-gap {
    color: rgb(119, 119, 119);
    margin-top: 16px;
    margin-bottom: 8px;
}

.admin-container h2.main-header-with-gap {
    font-size: 24px;
    line-height: 24px;
}
.admin-container h3.main-header-with-gap {
    font-size: 20px;
    line-height: 20px;
}

.admin-container .MuiInputBase-root, 
.admin-container .MuiFormControlLabel-root{
    margin-bottom: 16px !important;
}

.admin-container .MuiInputBase-root.noBottomMarg {
    margin-bottom: 0px !important;
}

.admin-container .MuiCheckbox-colorSecondary.Mui-checked {
    color: #00b5e2;
}

.admin-container .main-paper {
    padding: 16px;
}

/* why did you do that? that breaks the layout for all other cards.
.admin-container .MuiCardContent-root {
  padding: 16px 8px 8px 8px;
}
*/

.admin-container .upload-image-card {
    width: 100%;
    margin-bottom: 16px;
}

.admin-container .map-container-paper {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
}

.admin-container .upload-image-card img {
    max-width: 400px;
    max-height: 300px;
    margin-bottom: 16px;
}
.admin-container .map-card {
    width: calc(100% - 32px);
    margin-bottom: 16px;
    padding: 16px;
}

.admin-container .is-selected {
    background-color: #7b98bc !important;
}

.admin-container .selected-item {
    background-color: var(--text-background-color);
}

.admin-container .upload-image-card h3 {
    font-size: 20px;
    line-height: 20px;
    color: rgb(119, 119, 119);
    margin-top: 16px;
    margin-bottom: 16px;
}
.admin-container .map-container-paper h3 {
    font-size: 20px;
    line-height: 20px;
    color: rgb(119, 119, 119);
    margin-bottom: 16px;
}

.drawerContainer .Mui-selected .MuiTypography-root {
    font-weight: bold;
}

.admin-container .MuiTableCell-body a,
.drawerContainer a,
.drawerContainer .MuiTypography-root {
    color: #00b5e2;
    text-decoration: none;
}

.drawerContainer a:hover,
.drawerContainer .MuiTypography-root:hover {
    color: rgb(119, 119, 119);
}

/*
.admin-container .MuiBreadcrumbs-root {
  margin-bottom: calc(var(--gap) * 2);
}

 */

.admin-container .MuiBreadcrumbs-li,
.admin-container .MuiBreadcrumbs-li p,
.admin-container .MuiBreadcrumbs-separator {
    font-size: 24px;
    line-height: 24px;
}

.admin-container .rdw-editor-main {
    min-height: 100px;
    max-height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: var(--gap);
}
