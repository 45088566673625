.comment {
    display: flex;
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}

.ui .comments {
    padding-left: 20px;
}

.content {
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}
.content-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}
.author {
    font-weight: 700;
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}
.metadata {
    color: var(--primary-color);
    font-size: 0.875em;
    font-family: var(--text-font-family);
}

.ui.comments .comments-container .MuiPaper-root {
    box-shadow: 0px 0px 25px -7px #ccc;
}

.comments-container .MuiPaper-root {
    box-shadow: 0px 0px 25px -7px #ccc;
}

.ui.comments .comment .actions {
    font-size: 0.875em;
    font-family: var(--text-font-family);
}
.ui.comments .comment .actions a:last-child {
    margin-right: 0;
}
.ui.comments .comment .actions a {
    cursor: pointer;
    display: inline-block;
    margin: 0 0.75em 0 0;
    color: rgba(0, 0, 0, 0.4);
    margin-right: 0;
    text-align: right;
    width: 100%;
    position: relative;
}
.comment-textarea {
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}
.ui.form > button {
    padding: 10px;
}
.avatar > img {
    width: 75px;
    max-width: 75px;
}

.avatar {
    width: 75px;
    max-width: 75px;
}
.comment-pager {
    margin: 16px 0px 16px 0px;
}

.MuiPagination-ul > li > button.MuiPaginationItem-page.Mui-selected {
    background-color: var(--primary-color);
    color: #fff;
}

.MuiPagination-ul > li > button.MuiPaginationItem-page {
    color: var(--primary-color);
    padding: 0;
}

.portal-section .comment_paper {
    border-radius: var(--border-radius-paper);
}

.portal-section .project-comments {
    margin-top: calc(var(--gap)*2);
    margin-bottom: 96px;
}
.masonry-grid.comments-container{
    margin-top: 64px !important;
}