.side-image {
    height: 100%;
}

.MuiPaper-root.MuiCard-root.public-login-form-container.MuiPaper-elevation1.MuiPaper-rounded {
    width: 100%;
    margin: 0 calc(var(--gap) * 4) 0 calc(var(--gap) * 4);

}

.login-logo {
    max-width: 300px;
}

.center-now {
    margin: 0 auto;
}

.portal-section .public-login-form-container {
    margin: 0 auto;
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
    border-radius: var(--border-radius-paper);
    border: none;
    max-width: 480px;
}

.portal-section .public-register-form-container {
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0 0 25px -7px #ccc;
    border-radius: 10px;
}
.portal-section .public-login-form-content {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.portal-section .public-login-form-content .public-input-text{
    width:100%;
}

.portal-section .public-register-form-content {
    max-width: 620px;
    min-height: 350px;
    padding: 20px;
    display: flex;
    margin-bottom: calc(var(--gap) * 4) !important;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.portal-section .register-return-button-container > button {
    padding-left: var(--gap);
}

.portal-section .register-return-button {
    color:var(--primary-color);
    background-color: unset;
    box-shadow: none;
}
.portal-section .register-return-button:hover {
    background-color: unset;
    box-shadow: none;
}

.public-login-form-content .MuiTextField-root {
    margin-bottom: 16px !important;
}

.public-input-text {
    width: 300px;
}

.public-logo-image {
    position: relative;
}

.public-login-form-error-message {
    color: var(--primary-color);
    padding: 25px;
}

.public-login-form-link {
    margin-top: 16px;
    color: var(--primary-color);
    cursor: pointer;
}

.public-login-form-link:hover {
    color: var(--primary-color);
}

.button--primary {
    background-color: var(--primary-color);
    color: #ffffff;
}

.login-form-link.clickable {
    margin-top: var(--gap);
    margin-bottom: 0;
}

.login-buttons-container {
    width: 100%;
}

.portal-section .login-container{
    margin-top: 96px;
    margin-bottom: 192px;
}

.portal-section .login-container > div {
    padding-right: var(--gap);
    padding-left: var(--gap);

    margin-top: var(--gap2);
    margin-bottom: var(--gap2);
}

.portal-section  .login__create-new-account-button {
    background-color: var(--button-background-dark);
    color: white;
}

.portal-section  .login__create-new-account-button:hover {
    background-color: var(--primary-color);
    color: white;
}

@media (max-width: 576px) {
    .portal-section .MuiCard-root.public-login-form-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: calc(var(--gap) * 2);
    }
}

.portal-section .registration-info {
    margin: calc(var(--gap) * 8) 0;
    background-color: white;
    color: var(--grey-text);
}

.portal-section .registration-info > .MuiAlert-icon {
    color: var(--primary-color);
}

.portal-section .registration-confirmation-checkbox {
    margin-top: calc(var(--gap) * 8);
}

.portal-section .public-login-form-content .MuiAlert-root {
    font-family: var(--text-font-family);
    margin-bottom: var(--gap);
}