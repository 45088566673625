.campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-dayDisabled {
    background-color: white !important;
}

.campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-day p {
    color: white !important;
}

.campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-day:hover p {
    color: var(--text-color) !important;
}

.campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-dayDisabled p {
    color: var(--text-color) !important;
}

.action-buttons button:first-child {
    margin-right: 10px;
}

.project-data__timelinejs {
    padding-top: 250px;
}

.portal-section .appointment-form-container {
    margin-bottom: calc(var(--gap) *12);
}

.portal-section .checkbox-label .MuiButtonBase-root {
    padding: var(--gap);
}

.portal-section .checkbox-label .MuiIconButton-colorSecondary:hover {
    background-color: unset;
}

.portal-section .checkbox-label .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: unset;
}