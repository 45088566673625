
.proposal-map-wrapper {
    width: 100%;
}
.single-comment:hover {
    background: #bbbbbb;
    cursor: pointer;
}

.single-comment:hover * {
    color: white !important;
}

.hover-popup {
    background: #bbbbbb;
    background-color: #bbbbbb !important;
}

.masonry-grid.comments-container{
    margin-top: 24px !important;
}