.root {
    display: flex;
}
.appBar {
    z-index: 1201;
    background: #00b5e5;
}
.drawer {
    width: 200px;
    flex-shrink: 0;
}
.drawerPaper {
    width: 180px;
}

.drawerContainer {
    overflow: auto;
}
.content {
    flex-grow: 1;
    padding: 20px;
}
