.masonry-grid {
    display: flex;
    margin-left: calc(var(--gap) * -3);
    width: calc(100% + calc(var(--gap) * 3));
    margin-bottom: 96px;
    max-height: 500px;
    overflow: auto;
}

.masonry-grid_column {
    padding-left: calc(var(--gap) * 3);
    background-clip: padding-box;
}

.masonry-grid_column>div {
    margin-bottom: calc(var(--gap) * 3);
}