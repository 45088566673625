#rcc-decline-button,
#rcc-confirm-button {
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
    font-size: var(--text-font-size-smaller);
    font-family: var(--button-font-family);
}

.portal-section .App-footer-logo-container {
    display: block;
    width: 100%;
}
.portal-section .App-footer-logo-container img {
    max-width: 130px;
    max-height: 86px;
}
.portal-section .App-footer-logo-customer{
    margin-right: var(--gap);
    max-width: 130px;
}
