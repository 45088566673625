.side-image {
    height: 100%;
}

.MuiPaper-root.MuiCard-root.resetpwd-form-container.MuiPaper-elevation1.MuiPaper-rounded {
    width: 100%;
    margin: 0 calc(var(--gap) * 4) 0 calc(var(--gap) * 4);
}

.login-logo {
    max-width: 300px;
}

.center-now {
    margin: 0 auto;
}

.resetpwd-form-container {
    min-height: 60vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    margin-bottom: calc(var(--gap) * 4) !important;
    align-items: center;
    flex-direction: column;
}

.resetpwd-form-content {
    width: 450px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.resetpwd-form-content .MuiTextField-root {
    margin-bottom: 16px;
}

.public-input-text {
    width: 300px;
}

.public-logo-image {
    position: relative;
}

.resetpwd-form-link {
    margin-top: 16px;
    color: var(--primary-color);
    cursor: pointer;
}

.resetpwd-form-link:hover {
    color: var(--primary-color);
}

.button--primary {
    background-color: var(--primary-color);
    color: #ffffff;
}
