.banner-label {
    position: fixed;
    color: white;
    background-color: rgba(139, 0, 0, 0.6);
    box-shadow: 2px 2px 20px #888;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    width: 300px;
    top: 45px;
    left: -95px;
    text-align: center;
    padding: calc(var(--gap) * 2);
    z-index: 9999;
}
