@font-face {
    font-family: "suisse-works-regular";
    src: url('fonts/SuisseCuts/webFiles/SuisseWorks-Regular-WebXL.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Regular-WebXL.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Regular-WebXL.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Regular-WebXL.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Regular-WebXL.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "suisse-works-medium";
    src: url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.eot');
    /* IE9 Compat Modes */
    src: url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('fonts/SuisseCuts/webFiles/SuisseWorks-Medium-WebXL.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "suisse-intelcond-semibold";
    src: url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Semibold-WebXL.eot') format('embedded-opentype'),
        /* IE6-IE8 */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Semibold-WebXL.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Semibold-WebXL.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Semibold-WebXL.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "suisse-intelcond-medium";
    src: url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Medium-WebXL.eot') format('embedded-opentype'),
        /* IE6-IE8 */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Medium-WebXL.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Medium-WebXL.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('fonts/SuisseCuts/webFiles/SuisseIntlCond-Medium-WebXL.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: "protest-grotesk-text-regular";
    src: url('fonts/ProtestGrotesk/web/ProtestGrotesk-textregular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/ProtestGrotesk/web/ProtestGrotesk-textregular.woff') format('woff');
    /* Pretty Modern Browsers */
}

@font-face {
    font-family: "protest-grotesk-text-black";
    src: url('fonts/ProtestGrotesk/web/ProtestGrotesk-textblack.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/ProtestGrotesk/web/ProtestGrotesk-textblack.woff') format('woff');
    /* Pretty Modern Browsers */
}

@font-face {
    font-family: "protest-grotesk-text-bold";
    src: url('fonts/ProtestGrotesk/web/ProtestGrotesk-textbold.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('fonts/ProtestGrotesk/web/ProtestGrotesk-textbold.woff') format('woff');
    /* Pretty Modern Browsers */
}

@font-face {
    font-family: "helvetica-neue-medium";
    src: url('fonts/HelveticaNeue/HelveticaNeueMedium.ttf') format('truetype');
}

:root {
    --primary-color: #6bd5fc;
    --text-color: #4A4A4A;
    --text-background-color: #f0f0f0;
    --grey-text: #a5a5a5;
    --warning-color: #ffa500;

    --text-font-family: "protest-grotesk-text-regular";
    --text-font-size: 20px;
    --text-font-line-height: 32px;
    --text-font-size-smaller: 15px;

    --link-font-family: "protest-grotesk-text-black";

    --header-color: black;
    --header-h1-font-family: "protest-grotesk-text-black";
    --header-h1-size: 70px;
    --header-h1-line-height: 74px;

    --header-h2-font-family: "protest-grotesk-text-bold";
    --header-h2-size: 46px;
    --header-h2-line-height: 55px;

    --default-shadow: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
    --header-menu-shadow: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));

    --header-h3-font-family: "protest-grotesk-text-black";
    --header-h3-size: 20px;
    --header-h3-line-height: 32px;
    --header-h3-color: var(--primary-color);

    --mobile-menu-font-family: "protest-grotesk-text-bold";
    --mobile-menu-font-size: "15px";
    --mobile-menu-font-color: rgba(74, 74, 74, 0.5);

    --button-background: #a5a5a5;
    --button-background-dark: #4A4A4A;
    --button-text-color: white;
    --button-border: #a5a5a5;
    --button-background-hover: var(--primary-color);
    --button-border-hover: var(--primary-color);
    --button-font-family: "protest-grotesk-text-bold";

    --border-radius-paper: 8px;
    --border-radius-button: 25px;

    --footer-background-color: #E2E2E2;
    --footer-link-color: #4A4A4A;
    --footer-link-font-family: var(--text-font-family);
    --footer-text-color: #4A4A4A;
    --footer-button-background: #4A4A4A;
    --footer-button-font: white;

    --gap: 8px;
    --gap2: 16px;
}

/*
* Mobile font adaptation
*/

@media screen and (max-width: 560px) {
    :root {
        --header-h1-size: 42px;
        --header-h2-size: 28px;
        --header-h3-size: 16px;
        --text-font-size: 15px;
        --text-font-line-height: 8px;
        --text-font-size-smaller: 15px;
    }
}

body {
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.portal-section button {
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}

.portal-section .MuiLinearProgress-colorSecondary {
    background-color: var(--primary-color);
}

.portal-section .App-footer span {
    color: var(--footer-text-color);
    font-size: var(--text-font-size-smaller) !important;
}

.portal-section>div.MuiGrid-root.MuiGrid-container {
    position: absolute;
}

.portal-section .MuiTabs-indicator {
    background-color: var(--primary-color) !important;
}

/**
Headers h1, h2, h3 + link and text colors in general
 */

.portal-section h2,
.portal-section h3 {
    text-align: left;
}

.portal-section h1,
.portal-section h2,
.portal-section h3 {
    margin-top: 0;
}

.portal-section h2 {
    letter-spacing: -0.05em;
}

.portal-section h1,
.portal-section .MuiTypography-h1 {
    color: var(--header-color);
    font-size: var(--header-h1-size);
    line-height: var(--header-h1-line-height);
    font-family: var(--header-h1-font-family);
}

.portal-section h2,
.portal-section .MuiTypography-h2 {
    color: var(--text-color);
    font-size: var(--header-h2-size);
    line-height: var(--header-h2-line-height);
    font-family: var(--header-h2-font-family);
}

.portal-section h3,
.portal-section .MuiTypography-h3 {
    color: var(--primary-color);
    font-size: var(--header-h3-size);
    line-height: var(--header-h3-line-height);
    font-family: var(--header-h3-font-family);
}

.portal-section .text-color.MuiTypography-h1,
.portal-section .text-color.MuiTypography-h2,
.portal-section .text-color.MuiTypography-h3 {
    color: var(--text-color);
}

.portal-section p,
.portal-section li,
.portal-section .content-block,
.portal-section .MuiTypography-root.MuiTypography-body1 {
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
}

.portal-section .MuiTypography-subtitle1,
.portal-section p.MuiTypography-subtitle1 {
    color: var(--text-color);
    font-size: var(--text-font-size-smaller);
    font-family: var(--text-font-family);
}

.portal-section .MuiTypography-root.MuiTypography-body2 {
    color: var(--text-color);
    font-family: var(--header-h2-font-family);
    line-height: 1.5;
}

.portal-section .text-color-primary {
    color: var(--primary-color) !important;
}

.portal-section a {
    color: var(--primary-color);
    font-family: var(--link-font-family);
    text-decoration: none;
}

.portal-section a:hover {
    color: var(--primary-color);
}

.portal-section p {
    margin-top: 0;
    font-size: var(--text-font-size);
}

.portal-section span {
    font-size: var(--text-font-size);
    font-family: var(--text-font-family);
}

.portal-section span.MuiButton-label {
    font-size: var(--text-font-size-smaller) !important;
    font-family: var(--button-font-family) !important;
}

.portal-section .smaller {
    margin-top: -3px;
    font-size: var(--text-font-size-smaller);
}

/**
Forms / inputs
 */

.portal-section input,
.portal-section textarea {
    font-family: var(--text-font-family) !important;
}

.portal-section .MuiOutlinedInput-root {
    border-radius: var(--border-radius-paper);
}
.portal-section .with-text-field-border-radius {
    border-radius: var(--border-radius-paper);
}

/**
Property flags like shadows, sizes, gaps etc
 */

.portal-section .with-shadow {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
}


.portal-section .gap-top-none {
    margin-top: 0 !important;
}

.portal-section .gap-bottom-none {
    margin-bottom: 0 !important;
}

.portal-section .gap-top {
    margin-top: calc(var(--gap) * 2) !important;
}

.portal-section .gap-top-2 {
    margin-top: calc(var(--gap) * 4) !important;
}

.portal-section .half-gap-top {
    margin-top: calc(var(--gap));
}

.portal-section .gap-bottom {
    margin-bottom: calc(var(--gap) * 2);
}

.portal-section .gap-bottom_2 {
    margin-bottom: calc(var(--gap) * 4);
}

.portal-section .gap-left {
    margin-left: calc(var(--gap) * 2);
}

.portal-section .gap-left-1 {
    margin-left: var(--gap);
}

.portal-section .full-pad {
    padding: calc(var(--gap) * 2);
}

.portal-section .no-marg {
    margin-top: 0;
    margin-bottom: 0;
}

.portal-section .rounded-bottom-only,
.portal-section .MuiPaper-rounded.rounded-bottom-only {
    border-radius: 0 0 var(--border-radius-paper) var(--border-radius-paper);
}

.portal-section .clickable {
    color: var(--primary-color);
    cursor: pointer;
}

.portal-section .clickable:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.portal-section .App-logo {
    height: 40vmin;
    pointer-events: none;
}

.portal-section .MuiToggleButton-root,
.portal-section .MuiButton-root {
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
    border-radius: var(--border-radius-button);
    text-transform: none;
}

.portal-section .MuiButtonBase-root.button--colored {
    background-color: var(--primary-color);
    color: white;
}

.portal-section .team-container {
    margin-top: 51px;
}

.portal-section .team-member-tile-wrapper {
    margin-bottom: 192px;
}

.portal-section .project-team-tab__team-member-tile button.MuiButtonBase-root.MuiCardActionArea-root:hover {
    color: white;
}

.portal-section .project-team-tab__team-member-tile .MuiButtonBase-root.MuiCardActionArea-root {
    cursor: default;
    color: white;
}


@media (prefers-reduced-motion: no-preference) {
    .portal-section .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.portal-section .MuiTab-root {
    text-transform: none;
    font-size: var(--header-h3-size);
    line-height: 1.25;
    cursor: pointer;
    min-width: 140px;
}

.portal-section .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primary-color);
}

.portal-section .campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-day.MuiPickersDay-daySelected {
    background-color: var(--button-background) !important;
}

.portal-section .campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-day.MuiPickersDay-dayDisabled {
    background-color: gray;
}

.portal-section .campaign-portal-calendar .MuiPickersCalendar-transitionContainer .MuiPickersDay-day {
    background-color: var(--primary-color);
}

.portal-section .campaign-portal-calendar .MuiTypography-root.MuiTypography-body2.MuiTypography-colorInherit {
    font-size: var(--text-font-size-smaller);
}

.portal-section .MuiToggleButtonGroup-groupedVertical.Mui-selected {
    background-color: var(--button-background) !important;
    color: white;
}

.portal-section .main-paper {
    padding: 16px;
}

.portal-section .project-log-block__main-grid {
    hyphens: auto;
    width: 100%;
}

/**
This is the App Header Portal section
 */


.portal-section .App-main-image {
    overflow: hidden;
    margin-bottom: 96px;
}

.portal-section .App-main-image img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
}

.portal-section .App-main-image.isMobile img {
    height: 60vh;
}

@media screen and (max-width:960px) {
    .portal-section div.App-header-logo-container {
        left: calc(var(--gap) *2);
        top: calc(var(--gap) *2);
    }
}

.portal-section .App-header-logo-container {
    position: absolute;
    left: 32px;
    top: 32px;
    width: 130px;
    padding: var(--gap);
    border-radius: var(--border-radius-paper);
    background-color: white;
}
.App-header-big-customer-logo-container{
     width: 200px !important;

}


.portal-section .project-log-block__second-title,
.portal-section .project-log-block__first-title {
    margin-bottom: calc(var(--gap) * 2);
}

.portal-section .project-log-block__controls {
    margin-top: calc(var(--gap) * 2);
}

.portal-section .project-long-block__padding-left {
    padding-left: 15%;
    padding-right: calc(var(--gap) * 4);
}

@media screen and (max-width: 1200px) {
    .portal-section .project-long-block__padding-left {
        padding-right: calc(var(--gap) * 4);
    }
}

.portal-section .project-long-block__padding-right {
    padding-right: 15%;
    padding-left: calc(var(--gap) * 4);
}

.portal-section .project-long-block__margin-image-right {
    margin-right: calc(var(--gap) * 2);
}

.portal-section .project-log-block__card {
    display: flex;
    box-shadow: none;
    margin-bottom: calc(var(--gap) * 18);
}

.portal-section .App-header-logo-container img {
    width: 130px;
}
.portal-section .App-header-big-customer-logo-container img {
    width: 200px !important;
}
.portal-section .map-image {
    border-radius: var(--border-radius-paper);
    width: 100%;
}


.portal-section .App-header-menu {
    top: 32px;
    height: 35px;
    background-color: white;
    opacity: 0.9;
    border-radius: var(--border-radius-button);
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
    position: fixed;
    max-width: 1200px;
    z-index: 1000;
    width: calc(100% - 484px);
    left: 50%;
    margin-left: calc(calc(100% - 370px) / -2);
}
.portal-section .App-header-menu-with-big-customer-logo{
    width: calc(100% -  554px) !important;
    left: 50% !important;
    margin-left: calc(calc(100% - 522px) / -2) !important;
}

.portal-section .App-header-menu.full-width {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    left: 50%;
    margin-left: calc(calc(100% - 40px) / -2);
}

.portal-section .App-header-main-container {
    position: absolute;
    bottom: calc(20% + 64px);
    width: calc(100% - 430px);
    left: 50%;
    margin-left: calc(calc(100% - 370px) / -2);
}



@media screen and (min-width: 1800px) {

    .portal-section .App-header-main-container,
    .portal-section .App-header-menu-responsive {
        width: 1200px;
        margin-left: -600px;
    }

    .portal-section .App-header-menu.full-width {
        width: 1200px;
        margin-left: -600px;
    }
    .portal-section .App-header-menu-with-big-customer-logo{
        width: 1200px !important;
        margin-left: -600px !important;
    }
}

.portal-section .App-header-menu ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    margin: 0 calc(2 * var(--gap)) 0 calc(2 * var(--gap));
    align-items: center;
    flex-wrap: nowrap;
}

.portal-section .App-header-menu ul:first-child {
    height: 35px;
}

.portal-section .App-header-menu ul li {
    align-items: center;
    padding: calc(var(--gap) / 2);
    font-size: var(--text-font-size-smaller);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.portal-section .App-header-menu--entry {
    text-align: right;
    cursor: pointer;
}

.portal-section .App-header-menu--top-bar {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.portal-section .App-header-menu--entry.active {
    color: var(--primary-color);
}
@media screen and (min-width: 1500px) {
    .portal-section .App-header-menu--entry>button {
        width: 250px;
    }
}



.portal-section ul li.App-header-menu--custom-entry>a {
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
}

.portal-section ul li.App-header-menu--custom-entry.is-active>a,
.portal-section ul li.App-header-menu--custom-entry ul>li.is-active>a {
    color: var(--primary-color);
}

.portal-section ul li.App-header-menu--custom-entry ul {
    display: none;
    position: absolute;
    top: var(--menu-height);
    background-color: white;
    margin: 4px -20px 0 -20px;
    padding: 0 16px 10px 16px;
    border-bottom-left-radius: calc(var(--border-radius-paper) / 2);
    border-bottom-right-radius: calc(var(--border-radius-paper) / 2);
}

.portal-section ul li.App-header-menu--custom-entry:hover>ul {
    display: inherit;
}

.portal-section ul li.App-header-menu--custom-entry:hover>a {
    color: var(--primary-color);
}

.portal-section ul li.App-header-menu--custom-entry ul {
    padding: 0 16px 16px;
}

.portal-section ul li.App-header-menu--custom-entry ul li a {
    line-height: 1.5;
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size-smaller);
}

.portal-section ul li.App-header-menu--custom-entry>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.portal-section ul li.App-header-menu--custom-entry ul li a:hover {
    color: var(--primary-color);
}

.portal-section ul li.App-header-menu--custom-entry .menu-opener-icon {
    display: inline-block;
}

.portal-section ul li.App-header-menu--custom-entry .menu-opener-icon svg {
    margin-bottom: -6px;
}

/* default head button style */
.portal-section .MuiButtonBase-root.MuiButton-root {
    height: 35px;
}

/* default head button style */
.portal-section .App-header .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    background-color: white;
    color: var(--text-color);

}

/* header primary button style override */
.portal-section .App-header .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
    /* background-color: var(--primary-color); */
    color: var(--button-text-color);
}

.portal-section .App-header-menu--entry>button {
    margin-bottom: var(--gap);
    font-size: var(--text-font-size);
}

.portal-section .App-header-menu li:hover {
    color: var(--primary-color);
}

.portal-section .App-header-button--button {
    cursor: pointer;
    text-align: right;
}

.portal-section .App-header-menu--entry-buttons {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 250px;
}

.main-header-text {
    max-width: 1200px;
    margin: 0 auto;
}

.main-header-text .MuiTypography-root,
.main-header-text .MuiTypography-root.header-text {
    color: white;
    text-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
}

.portal-section .MuiTypography-root.header-title .header-title-fragments {
    color: white;
    margin-bottom: var(--gap);
}

.portal-section .MuiTypography-root.header-title .header-text {
    margin-top: var(--gap);
    color: white;
}


.portal-section .portal-header__button-container>button {
    margin-right: var(--gap);
}



/*
.portal-section .App-main {
    margin: calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 2);
}

.portal-section .App-main-list {
    margin: 0 calc(var(--gap) * 2) 0 calc(var(--gap) * 2);
}
*/

.portal-section .App-data-container.App-content-container {
    padding: 0 calc(var(--gap) * 2);
}

.portal-section .App-list-container-100 {
    width: 100%;
}

.portal-section .App-list-container-header {
    font-size: var(--header-h2-size);
    line-height: var(--header-h2-line-height);
    width: 100%;
    margin-bottom: calc(var(--gap) * 2);
    margin-top: calc(var(--gap) * 2);
}

.portal-section .App-list-container-body {
    margin: 0;
}

.portal-section .App-cms-container {
    flex-grow: 1;
    flex-basis: 20px;
    padding-top: var(--gap);
    padding-bottom: calc(var(--gap) * 2);
    text-align: left;
    max-width: 100%;
}

.portal-section .App-cms-container-textblock {
    margin: calc(var(--gap) * 2) 0;
}

.portal-section .App-cms-container .project-long-block-btn {
    background-color: var(--button-background-hover)
}

.portal-section .App-data-container {
    max-width: 1200px;
}


.portal-section .App-data-header {
    width: 100%;
    margin-top: calc(var(--gap) * 2);
}

.portal-section .App-data-content {
    margin-top: calc(var(--gap) * 2);
}


/**
 Button section
 */

.portal-section .MuiButtonBase-root {
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
}

.portal-section .MuiToggleButtonGroup-root .MuiButtonBase-root {
    padding-left: 20px;
    padding-right: 20px;
}

.portal-section .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.Mui-checked {
    padding: var(--gap);
    cursor: pointer;
}

.portal-section .MuiIconButton-root.MuiPickersDay-day {
    padding: var(--gap);
    cursor: pointer;
}

/** looks like we need a fix for the card action arena */
.portal-section .MuiButtonBase-root.MuiCardActionArea-root {
    padding-left: 0;
    padding-right: 0;
}

.portal-section .button--primary,
.portal-section .MuiButton-textPrimary {
    background-color: var(--button-background);
    color: var(--button-text-color);
    border: var(--button-border);
    font-size: var(--text-font-size);
    text-transform: unset;
    cursor: pointer;
}

.portal-section .button--primary:hover,
.portal-section .MuiButton-textPrimary:hover {
    background-color: var(--button-background-hover);
    color: white;
}

.portal-section .MuiSvgIcon-colorPrimary {
    color: var(--button-text-color);
}

/**
GENERAL Content Section
 */


.portal-section .content-block {
    font-size: var(--text-font-size);
}

.portal-section .image-gallery {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-width: 500px;
    margin-left: calc(var(--gap) * -2);
    margin-right: calc(var(--gap) * -2);
    margin-top: calc(var(--gap) * -2);
    margin-bottom: calc(var(--gap) * 2);
}


.portal-section .App-sidebar {
    flex-grow: 1;
    flex-basis: 20px;
    padding: calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 1) calc(var(--gap) * 2);
    text-align: left;
}

.portal-section .Proposal-Tab {
    flex-grow: 1;
    flex-basis: 20px;
    margin: calc(var(--gap) * 2) 0 calc(var(--gap) * 2) 0;
    padding: calc(var(--gap) * 2) calc(var(--gap) * 2) calc(var(--gap) * 1) calc(var(--gap) * 2);
    text-align: left;
}

.portal-section .App-sidebar-h3 {
    margin-bottom: calc(var(--gap) * 2);
    margin-top: 0;
}

.portal-section .button--primary,
.portal-section .MuiButton-textPrimary {
    background-color: var(--button-background);
    color: var(--button-text-color);
    border: var(--button-border);
    font-size: var(--text-font-size);
    text-transform: unset;
    cursor: pointer;
}

.portal-section .button--primary:hover,
.portal-section .MuiButton-textPrimary:hover {
    background-color: var(--button-background-hover);
    color: white;
}

.portal-section .MuiButton-outlined {
    cursor: pointer;
}

.portal-section .MuiSvgIcon-colorPrimary {
    color: var(--button-text-color);
}

/**
footer starts here
 */

.portal-section .App-footer {
    background-color: var(--footer-background-color);
    padding-right: calc(var(--gap) *2);
    padding-left: calc(var(--gap) *2);
}

@media screen and (max-width: 960px) {
    .portal-section .App-footer {
        margin: 0;
        padding-top: var(--gap2) !important;
    }

    .portal-section .App-footer2 {
        margin: 0 !important;
    }
}


@media screen and (max-width: 960px) {
    div.portal-section .App-footer .App-footer-outer {
        padding-top: 0;
    }
}

.portal-section .App-footer .App-footer-outer {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 96px;
}


.portal-section .App-footer h2,
.portal-section .App-footer h3 {
    color: var(--footer-text-color);
}

.portal-section .App-footer p {
    color: var(--footer-text-color);
}

.portal-section ol>li {
    font-size: var(--text-font-size);
}

.portal-section .App-footer a {
    color: var(--footer-link-color);
    font-family: var(--footer-link-font-family);
    cursor: pointer;
    font-size: var(--text-font-size-smaller);
}

@media screen and (max-width:960px) {
    .portal-section .App-footer div.steg-copyright {
        text-align: unset;
    }
}

.portal-section .App-footer .steg-copyright {
    text-align: right;
}


.portal-section .App-sidebar--list-entry {
    margin-bottom: calc(var(--gap) * 2);
}

.portal-section .App-link {
    /* color: var(--color-primary); */
    cursor: pointer;
}

.portal-section input.MuiInputBase-input.MuiOutlinedInput-input:focus+fieldset {
    border-color: var(--primary-color);
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-required.Mui-required {
    color: var(--text-color);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.portal-section .MuiFormControl-root,
.portal-section .selectbox-container {
    margin-bottom: 16px !important;
}

@media (max-width: 576px) {

    .portal-section .App-footer,
    .portal-section .App-footer2 {
        margin: 0;
        padding-right: calc(var(--gap) *2);
        padding-left: calc(var(--gap) *2);
    }

    .MuiPaper-root.MuiCard-root.public-login-form-container.MuiPaper-rounded {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: calc(var(--gap) * 2);
    }

    .MuiContainer-root {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


.portal-section .App-header .MuiButtonBase-root {
    background-color: white;
    color: gray;
    border-radius: var(--border-radius-button);
    text-transform: unset;
}


/* footer button style */
.portal-section .App-footer-outer .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    background-color: var(--footer-button-background);
    color: var(--footer-button-font);
    border-radius: var(--border-radius-button);
    text-transform: unset;
}

.portal-section .App-footer-outer .MuiButtonBase-root.MuiButton-root.MuiButton-text span {
    color: white;
}

.portal-section .MuiInputBase-root .MuiOutlinedInput-inputMarginDense {
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--footer-text-color);
    /* color text footer text fields need to be fixed*/
}

.portal-section .MuiFormControl-root .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: 5px;
    padding-bottom: 100px;
    color: var(--footer-text-color);
}

div.react-images__container.css-br29ai {
    display: block;
    height: auto;
    margin-bottom: 192px;
}

.react-images__frame {
    overflow: hidden !important;
}

.react-images__navigation>button {
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
}

.react-images__navigation>button>svg {
    color: var(--primary-color);
}

.portal-section .App-data-container>div>div>div.MuiCard-root>button:hover {
    background-color: white;
    color: white;
}

.portal-section .App-data-container>div>div>div.MuiCard-root>button {
    background-color: white;
    color: white;
}

.portal-section .result-tile-container {
    margin-bottom: 96px;
}

.portal-section .tile__card .MuiCardContent-root {
    padding: 20px;
}

.portal-section .tile__card h2 {
    letter-spacing: -0.05em;
}

.portal-section .tile__card>button {
    background-color: white;
    color: white;
}

.html-content-force-style span,
.html-content-force-style p,
.html-content-force-style strong,
.html-content-force-style li,
.html-content-force-style a {
    color: var(--text-color) !important;
    font-size: var(--text-font-size) !important;
    font-family: var(--text-font-family) !important;
}

.portal-section .App-footer .html-content-force-style span,
.portal-section .App-footer .html-content-force-style p,
.portal-section .App-footer .html-content-force-style strong,
.portal-section .App-footer .html-content-force-style li,
.portal-section .App-footer .html-content-force-style a {
    font-size: var(--text-font-size-smaller) !important;
}

.portal-section .MuiAlert-root .MuiAlert-icon {
    font-size: 32px;
}

.portal-section .MuiAlert-root .MuiAlert-message {
    font-size: var(--text-font-size) !important;
    font-family: var(--text-font-family) !important;
}

/**/

.portal-section .MuiCardActions-root {
    padding: var(--gap2);
    padding-top: 0;
}

.portal-section .MuiPaper-root {
    transition: none !important;
}

.CookieConsent {
    z-index: 10000 !important;
}

.portal-section .welcome-message-header {
    margin-bottom: 40px;
}

.portal-section .project-motivation {
    margin-bottom: 192px;
}

.portal-section .project-motivation>.MuiGrid-item {
    padding-bottom: 0;
}

.portal-section .welcome-message {
    margin-bottom: calc(var(--gap) * 3);
}

/*font family for the inputs*/
.portal-section .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    font-family: var(--text-font-family);
}

.portal-section .portal-section-body-position .appointment-container {
    margin-bottom: 192px;
}

.portal-section .portal-section-body-position .App-main .MuiAlert-standardInfo {
    margin-bottom: 96px;
}

.portal-section .campaign-portal-calendar .MuiPickersSlideTransition-transitionContainer .MuiPickersCalendar-week .MuiPickersDay-day.MuiPickersDay-dayDisabled span.MuiIconButton-label p {
    font-size: 15px
}