.calculator-header{
    text-align: center !important;
}
.calculator-content-container {
   display: flex;
   position: relative;
   width: 80% !important;
   max-width: 80%;
   margin: auto;
   justify-self: center;


}
.calculator-content-container .currency-input{
    border: solid 1px var(--button-border) !important;
    padding: 8.5px 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 970px) {
    .calculator-content-container {
    flex-direction: column;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    width: 100% !important;
    }
}

.calculator-content-container__calculation-section{
    border-radius: var(--border-radius-paper);
    background-color:var(--text-background-color);
    position: absolute;
    top:5%;
    padding: 2%;
    width: 20%;
    height: calc(100% - 17%);
    justify-content: center;

}
@media screen and (max-width: 970px) {
    .calculator-content-container__calculation-section {
     box-shadow: unset !important;
     position: unset;
     border: unset;
     width: 100%;
     padding: 10% 4% 10% 4%;
     filter:unset;
     justify-content: space-between;
     display:unset !important;
    }
}

@media screen and (max-width: 970px) {
    .calculator-content-container .calculator-content-container__calculation-section-mobile {
        margin-bottom: calc(var(--gap) * 4);
    }
}

@media screen and (max-width: 970px) {
    .calculator-content-container__calculation-section .MuiGrid-container {
     display:unset !important;
    }
}

.calculator-content-container__calculation-section .currency-input{
  background-color:white;
}
.calculator-content-container__kfw-types{
   font-size: var(--text-font-size) !important;
}
.calculator-content-container__kfw-types .MuiSelect-select{
     background-color:white;
}
.calculator-content-container .main-header{
    font-weight: 700;
}
@media screen and (max-width: 970px) {
    .calculator-content-container .main-header {
      font-size: var(--header-h3-size) !important;
    }
}

@media screen and (max-width: 760px) {
    .calculator-content-container .main-header {
      font-size: var(--text-font-size-smaller) !important;
    }
}
.calculator-content-container__result-section{
    border-radius: var(--border-radius-paper);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10% 3% 7% 22%;
    margin-left: 15%;
    color:var(--grey-text);
    color:var(--grey-text);
    width: 85% !important;
}

@media screen and (max-width: 970px) {
    .calculator-content-container__result-section {
     box-shadow: unset !important;
     margin-left: 0;
     padding: 10% 4% 10% 4%;
     width: 100% !important;
    }
}
@media screen and (max-width: 970px) {
   .MuiGrid-container {
     display:unset !important;
    }
}
@media screen and (max-width: 970px) {
    .calculator-content-container__result-section__percentage-symbol p{
          font-size: var(--text-font-size-smaller) !important ;
    }
}
.calculator-content-container__calculation-section__edit-button {
    margin: auto;
    color: var(--text-color) ;
    align-self: end;

}

@media screen and (max-width: 970px) {
    .calculator-content-container__calculation-section__edit-button {
     margin-top: 10px !important;
    }
}

.calculator-content-container__result-section__error-message{
    width: 35.8rem;
    height: 7.5rem;
}
@media screen and (max-width: 970px) {
    .calculator-content-container__result-section__error-message {
        height: unset;
    }
}
.calculator-content-container__result-section__error-message__content{
    width: 100%;
    font-weight: 700 !important;
    color: var(--grey-text) !important;
    height: 100%;
}

.calculator-content-container__result-section .MuiTextField-root {
    max-width: 245px;
}

@media screen and (max-width: 970px) {
    .calculator-content-container__result-section .MuiTextField-root {
        max-width: unset !important;
    }

}

.calculator-content-container__result-section .percentage-field{
    max-width: 275px !important;

}

@media screen and (max-width: 970px) {
    .calculator-content-container__result-section .percentage-field {
        width: 100% !important;
        max-width: none !important;
    }
}

.calculator-content-container__result-section .percentage-field input{
    font-size: var(--text-font-size) !important;
}