.side-image {
    height: 100%;
}
.login-form-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login-form-content {
    width: 300px;
    height: 150px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.login-form-content .MuiTextField-root {
    margin-bottom: 16px;
}

.input-text {
    width: 300px;
}
.logo-image {
    /*bottom: 54px;*/
    position: relative;
}
.login-form-error-message {
    color: red;
    padding: 25px;
}

p.login-form-link {
    margin-top: var(--gap);
    color: var(--grey-text);
}

p.login-form-link:hover {
    color: var(--primary-color);
}
p.login-form-link {
    cursor: pointer;
}

