.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
    width: 80%;
}

.portal-section .app-mobile-header .MuiButtonBase-root{
    color : var(--primary-color);
    position: fixed;
    right: 0;
    top: 0;
    padding: var(--gap);
    z-index: 2;
}

.portal-section .app-mobile-header .MuiButtonBase-root > span > svg {
    height: 36px;
    width: 36px;
}

.MuiButtonBase-root.MuiIconButton-root.close-button {
    position: absolute;
    right: 0;
    top: 0;
    color:  var(--primary-color);
    z-index: 3;
}

.MuiButtonBase-root.MuiIconButton-root.close-button > span > svg {
    height: 36px;
    width: 36px;
}



.drawer-title .MuiTypography-root {
    color: var(--header-color);
    font-family: var(--mobile-menu-font-family);
    font-size: var(--mobile-menu-font-size);
}

.drawer-items .MuiButtonBase-root {
    color: var(--mobile-menu-font-color);
    font-family: var(--mobile-menu-font-family);
    font-size: var(--mobile-menu-font-size);
}

.drawer-items .MuiTypography-root.MuiTypography-body1 {
    font-family: var(--mobile-menu-font-family);
    font-size: var(--mobile-menu-font-size);
    margin-bottom: 16px;
}

.drawer-items .MuiListItem-root {
    padding: 0;
}

.drawer-items.MuiList-root {
    margin: calc(var(--gap)*2);
}

.drawer-items .active {
    color: var(--primary-color);
}

.App-header-logo-customer {
    width: 100px;
}

.App-header-logo-steg {
    width: 120px;
    margin-right: calc(var(--gap)*2);
}

.drawer-logo {
    display: flex;
    align-items: center;
    margin: calc(var(--gap)*2);
    justify-content: space-between;
    margin-bottom: 50px;
     padding-top: 4px;
}

.drawer-btns .MuiGrid-root.App-header-menu--entry-buttons.MuiGrid-item {
    display: flex;
    flex-direction: column;
    margin: calc( var(--gap ) *  2);
}

.drawer-btns .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  background-color: white;
  color: var(--header-color);
}

.drawer-btns .MuiButtonBase-root {
    border-radius: var(--border-radius-button);
    text-transform: unset;
}

.App-header-menu--entry > button {
    margin-bottom: var(--gap);
    font-size: var(--text-font-size);
    width: 250px;
}

.drawer-btns .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
    background-color: var(--primary-color);
    color: var(--button-text-color);
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    width: 75%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0.9;
    padding: 9px;
}

/* the new shadow class is defined because the other class is defined only under the portal section and hadn t removed the old one because it may cause other problem */
.with-shadow {
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.15));
}
.drawer-items.MuiList-root{
    margin-left: -9px !important;
    margin-right: -9px !important;
}

 .drawer-title, .list-entry-label {
    /* 16px margin + 9px padding */
    padding-left: 25px !important;
}
.rich-list-item {
    flex-direction: column;
    align-items: flex-start !important;
}

.rich-list-item__label {
    width: 100%;
    margin: 0 !important;
  
}

.rich-list-item__children{
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.rich-list-item__children__item .MuiListItemText-root {
    /* 16px margin + 9px padding + 32px indentions */
    padding-left: 57px !important;

}
.list-item .MuiTypography-root.MuiTypography-body1{
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}
.rich-list-item__label{
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}
.list-item .rich-list-item__label .MuiTypography-root.MuiTypography-body1{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
