.login-button {
    color: white;
    border: 0;
    height: 35px;
    margin: 10px 0;
    padding: 20px 30px;
    background: linear-gradient(45deg, #00b5e2 30%, #00b5e2 90%);
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
    border-radius: 3px;
}
