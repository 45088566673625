.portal-cms-image {
    max-width: 100%;
}
.faq-questions-list__response-list{
    padding-left: 25px;
}
.faq-questions-list__item{
    border-bottom: 1px solid;
}

.faq-questions-list__item__answer__content{
    display: flex;
    align-items: flex-start;
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
}
.faq-questions-list__item__question__text span{
    font-weight: 600;
}